
@media print {

  body {
    -webkit-print-color-adjust: exact;
  }


  @page {
    size: A4 landscape;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    -webkit-print-color-adjust: exact;
  }

  #content {
    max-width: 640px;
    width: 640px !important;
    padding: 8px !important;
  }
  .container {
    width: 640px;
  }

  header,
  footer,
  #headermeta,
  #top,
  #navopenclose,
  .copyright,
  .teaser-copyright,
  #popup-wrapper {
    display: none !important;

  }




  h1 {
    font-size: 26px !important;
  }
  h2 {
    font-size: 22px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  h4 {
    font-size: 16px !important;

  }
  p {
    font-size: 12px !important;
    line-height: 1.4;
  }

  .herstellerwrapper2 img {
    border: 1px solid #ddd;
  }

  * {
    -webkit-print-color-adjust: exact !important;
  }

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    // content: " (" attr(href) ")";
  }

  abbr[title]:after {
    // content: " (" attr(title) ")";
  }

  // Don't show links that are fragment identifiers,
  // or use the `javascript:` pseudo protocol
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }



  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    // page-break-after: avoid;
  }


}
